/*
    base: vars.scss
*/

// GLOBAL VARIABLES
//---------------------------------------------------------
$unit: 4px;
$scale: 0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 24, 45;

// UI VARIABLES
//---------------------------------------------------------
$header-height: 75px;
$footer-height: 72px;
//width + offset
$container-width: 1110px + 24px;

// COLORS
//---------------------------------------------------------
/*
    webtool for checking contrast:
    https://webaim.org/resources/contrastchecker/
*/
//grey colors
$grey-30: #303030;
$grey-66: #666;
$grey-82: #828282;
$grey-c4: #c4c4c4;
$grey-d8: #d8d8d8;
$grey-f2: #f2f2f2;
$grey-ef: #efefef;

//background colors
$black: #000;
$white: #fff;
$background-color: #121415;
$background-overlay: #061129b3;

//main colors
$color-primary: #3498db;
$color-secondary: #f9b601;

$color-success: #2ecc71;
$color-error: #e74c3c;
$color-warning: #e67e22;

// BORDERS
//---------------------------------------------------------
$border-color: $grey-c4;
$border-rounded: $unit;
$border-circle: 1000px;

// TYPOGRAPHY
//---------------------------------------------------------
$color-text-primary: $white;
$color-text-placeholder: $grey-d8;
$color-text-secondary: #a9aaab;

$type-ty: 10px;
$type-xs: 12px;
$type-sm: 14px;
$type-base: 16px;
$type-mds: 22px;
$type-md: 32px;
$type-lg: 36px;
$type-xl: 54px;

// RESPONSIVE
//---------------------------------------------------------
//responsive variables
$responsive-sm: 576px;
$responsive-md: 768px;
$responsive-lg: 992px;
$responsive-xl: 1200px;

//spacers building configs
$modifiers: (
  "": "",
  "t": "-top",
  "r": "-right",
  "b": "-bottom",
  "l": "-left",
);

$responsiveConfig: (
  $responsive-sm: "sm",
  $responsive-md: "md",
  $responsive-lg: "lg",
  $responsive-xl: "xl",
);

// FLUID TYPOGRAPHY
//---------------------------------------------------------
$fluid-type-modifier: 2px;
//set boundaries within fluid will work
$fluid-min-width: $responsive-sm;
$fluid-max-width: $responsive-xl;
//set min & max font sizes
$fluid-min-font: $type-base - $fluid-type-modifier;
$fluid-max-font: $type-base;
