/*
    utils: flex
*/

.flex {
    display: flex;

    &--primary {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: $responsive-md) {
            justify-content: center;
        }
    }

    &--center {
        align-items: center;
    }
    
    &--jc {
        &--space-between {
            justify-content: space-between;
        }
        &--center {
            justify-content: center;
        }
        &--end {
            justify-content: flex-end;
        }
    }
    
    &--grow {
        flex-grow: 1;
    }
}