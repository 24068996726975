/*
    components: content
*/

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__header {
        height: $header-height;
        border-bottom: 1px solid $border-color;
        background-color: $white;
        flex-shrink: 0;
    }
    &__main {
        display: flex;
        flex-grow: 1;
        flex-flow: column;
        flex-wrap: nowrap;

        padding: calc(#{$unit} * 6);
        overflow-y: auto;
        scroll-behavior: smooth;
        
        //container sizes
        &--md {
            max-width: 1100px;
        }
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // due to the desired initial flex-end behaviour, if we have two groups of buttons their order is reversed
        // for example
        //    - delete button needs to be under save button in HTML to be pushed on the other side
        flex-flow: row-reverse;

        height: $footer-height;
        min-height: $footer-height;
        padding: 0 calc(#{$unit} * 6);
        border-top: 1px solid $border-color;
        background-color: $white;
    }
}
