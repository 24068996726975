/*
    utils: align
*/

.align {
    &--center {
        margin: 0 auto;
    }
    &--middle {
        vertical-align: middle;
    }
}