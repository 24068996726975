/*
    components: animation.scss
*/

.animation {
  &--scroll {
    animation: scroll 500ms ease-in-out alternate infinite;
  }
}

@keyframes scroll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(3px);
  }
}
