/*
    components: section.scss;
*/

.section {
    &--primary {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-image: url(./../../assets/images/section-primary.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        height: 95vh;
        padding: 0 12px;
        @media (max-width: $responsive-md) {
            height: 80vh;
        }

        &__title {
            width: 100%;
            max-width: calc(#{$container-width} - 24px);
        }
        &__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: calc(#{$container-width} - 24px);
            margin-bottom: 40px;
        }
    }

    &--secondary {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(./../../assets/images/section-secondary.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        height: 80vh;
        h2 {
            text-align: center;
            max-width: 950px;
            padding: 0 12px;
        }
    }
}