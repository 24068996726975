/*
    base: typography.scss
*/

// Importing fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

// Global style
h1,h2,h3,h4,h5,h6 {
    margin: 0;
}

a {
    text-decoration: none;
    color: $color-text-primary;
}

address {
    font-style: normal;
}

p {
    margin: 0;
}

