/*
    components: layout
*/

$sidebar-width: 300px;
$mobile-height: 50px;

.layout {
    display: flex;
    @media (max-width: $responsive-md) {
        flex-direction: column;
    }

    &__aside {
        position: relative;
        z-index: 2;
        width: $sidebar-width;
        height: 100vh;
        background-color: $color-primary;
        color: $white;

        &__close {
            display: none;
        }

        @media (max-width: $responsive-md) {
            position: fixed;
            top: 0;
            bottom: 0;
            left: -100%;
            transition: left 300ms ease-in-out;

            &.active {
                left: 0;
            }
            
            &__close {
                display: block;
            }
        }
    }
    &__main {
        position: relative;
        width: calc(100% - #{$sidebar-width});
        height: 100vh;
        @media (max-width: $responsive-md) {
            width: 100%;
            height: calc(100vh - #{$mobile-height});
        }
    }

    &__mobile {
        display: none; 
        align-items: center;
        justify-content: space-between;
        
        height: $mobile-height;
        padding: calc(#{$unit} * 4);
        border-bottom: 1px solid $border-color;
        
        background-color: $color-primary;
        color: $white;
        
        @media (max-width: $responsive-md) {
            display: flex;
        }
    }
}