/*
    components: footer.scss
*/

.footer {
    &--primary {
        padding-top: calc(#{$unit} * 12);
        padding-bottom: calc(#{$unit} * 12); 
    }
    &--secondary {
       padding: calc(#{$unit} * 12) 12px; 
    }
    &__nav {
        height: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media (max-width: $responsive-md) {
            flex-direction: row;
            align-items: flex-start;
            .nav {
                flex-direction: column;
                width: 50%;
                
                &__item {
                    margin-bottom: calc(#{$unit} * 6);
                }
            }
        }
    }
}