/*
    components: nav.scss;
*/

.nav {
    display: flex;
    
    @media (max-width: $responsive-md) {
        display: none;
    }

    &__item {
        margin-right: calc(#{$unit} * 6);
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }
    }
}