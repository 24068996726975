/*
    base: base-styles.scss
*/

* {
    box-sizing: border-box;
}

html, body {
    overflow-x: hidden
}

body {
    //fluid typography
    @include fluid-type($fluid-min-width, $fluid-max-width, $type-base - $fluid-type-modifier, $type-base);
    
    //fixed typography
    //font-size: $type-base;

    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    color: $color-text-primary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: $background-color;
}