/*
    components: icons
*/

.icon {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  background-color: $color-text-primary;
  mask-repeat: no-repeat;
  mask-position: 50%;
  transition: background-color 300ms ease-in-out;

  //icon sizes
  &--ty {
    width: calc(#{$unit} * 3);
    height: calc(#{$unit} * 3);
  }
  &--xs {
    width: calc(#{$unit} * 4);
    height: calc(#{$unit} * 4);
  }
  &--sm {
    width: calc(#{$unit} * 5);
    height: calc(#{$unit} * 5);
  }
  &--base {
    width: calc(#{$unit} * 6);
    height: calc(#{$unit} * 6);
  }
  &--md {
    width: calc(#{$unit} * 8);
    height: calc(#{$unit} * 8);
  }
  &--lg {
    width: calc(#{$unit} * 12);
    height: calc(#{$unit} * 12);
  }

  //icons
  &--scroll-arrows {
    mask-image: url("./../../assets/icons/scroll-arrows.svg");
  }
  &--social-facebook {
    mask-image: url("./../../assets/icons/social-facebook.svg");
    transition: all 250ms ease-out;
    &:hover {
      transform: scale(1.3);
    }
  }
  &--social-instagram {
    mask-image: url("./../../assets/icons/social-instagram.svg");
    transition: all 250ms ease-out;
    &:hover {
      transform: scale(1.3);
    }
  }
  &--social-linkedin {
    mask-image: url("./../../assets/icons/social-linkedin.svg");
    transition: all 250ms ease-out;
    &:hover {
      transform: scale(1.3);
    }
  }
  &--social-youtube {
    mask-image: url("./../../assets/icons/social-youtube.svg");
    transition: all 250ms ease-out;
    &:hover {
      transform: scale(1.3);
    }
  }

  //color modifiers
  &--primary {
    background-color: $color-primary;
  }
  &--white {
    background-color: $white;
  }
}
