/*
    utils: type
*/

.type {
  //sizes
  &--ty {
    //fluid
    @include fluid-type(
      $fluid-min-width,
      $fluid-max-width,
      $type-ty - $fluid-type-modifier,
      $type-ty
    );
    //static
    //font-size: $type-ty
  }
  &--xs {
    //fluid
    @include fluid-type(
      $fluid-min-width,
      $fluid-max-width,
      $type-xs - $fluid-type-modifier,
      $type-xs
    );
    //static
    //font-size: $type-xs
  }
  &--sm {
    //fluid
    @include fluid-type($fluid-min-width, $fluid-max-width, $type-sm, $type-sm);
    //static
    //font-size: $type-sm
  }
  &--base {
    //fluid
    @include fluid-type(
      $fluid-min-width,
      $fluid-max-width,
      $type-base - $fluid-type-modifier,
      $type-base
    );
    //static
    //font-size: $type-base
  }
  &--mds {
    //fluid
    @include fluid-type(
      $fluid-min-width,
      $fluid-max-width,
      $type-mds - $fluid-type-modifier,
      $type-mds
    );
    //static
    //font-size: $type-md
  }
  &--md {
    //fluid
    @include fluid-type($fluid-min-width, $fluid-max-width, 16px, $type-md);
    //static
    //font-size: $type-md
  }
  &--lg {
    //fluid
    @include fluid-type($fluid-min-width, $fluid-max-width, 24px, $type-lg);
    //static
    //font-size: $type-lg
  }
  &--xl {
    //fluid
    @include fluid-type($fluid-min-width, $fluid-max-width, 32px, $type-xl);
    //static
    //font-size: $type-xl
  }

  //font weight
  &--wgt {
    &--light {
      font-weight: 300;
    }
    &--regular {
      font-weight: 400;
    }
    &--bold {
      font-weight: 600;
    }
  }
  // colors
  &--color {
    &--primary {
      color: $color-text-primary;
    }
    &--secondary {
      color: $color-text-secondary;
    }
    &--brand {
      color: $color-primary;
    }
    &--error {
      color: $color-error;
    }
    &--success {
      color: $color-success;
    }
    &--black {
      color: $black;
    }
  }

  //style
  &--uppercase {
    text-transform: uppercase;
  }

  //alignment
  &--center {
    text-align: center;
  }
}
