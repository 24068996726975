/*
    components: header.scss;
*/

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $container-width;
    height: $header-height;
    margin: 0 auto;

    &__logo {
        width: 110px;
    } 
}